import React, {useRef, useState} from "react"
import { motion } from 'framer-motion';

import "../../assets/styles/servicehomestyles.scss"

import timeImage from "../../assets/images/time.svg"
import appImage from "../../assets/images/apps.svg"
import webImage from "../../assets/images/web.svg"
import downArrow from "../../assets/images/arrow-down.svg"

export default function ServiceHome() {

    const [timeActive, setTimeActive] = useState(0);
    const [appActive, setAppActive] = useState(0);
    const [webActive, setWebActive] = useState(0);
    // onClick={() => {setTimeActive(0); setAppActive(0); setWebActive(0);}}
    const timeRef = useRef(null);
    const appRef = useRef(null);
    const webRef = useRef(null);

    function removeClick(e) {
        if(timeActive === 1 && !timeRef.current.contains(e.target)){
            setTimeActive(0)
        }
        if(appActive === 1 && !appRef.current.contains(e.target)){
            setAppActive(0)
        }
        if(webActive === 1 && !webRef.current.contains(e.target)){
            setWebActive(0)
        }
    }

    return (
        <>
        <section className="section home-services" onClick={removeClick}>
            <div className="container">
                <div className="columns">

                    <div className="column is-4">
                        <div className="column-wrap">
                        <motion.div  className="serv-head time" onClick={timeActive === 0 ? () => setTimeActive(1) : () => setTimeActive(0)} whileHover={{
                            scale: 1.1,
                            transition: {
                                duration: .2
                            }
                            }}>

                                <h3>Managing time <br/>and Processes</h3>
                            </motion.div>

                            <div className="txt-wrap">
                                <p>We help develop and execute a clear and strategic roadmap with priorities that are closely linked to your business goals, we begin by understanding where you are, where you want to go and undertake steps to get you there.</p>
                                <button className="button" onClick={timeActive === 0 ? () => setTimeActive(1) : () => setTimeActive(0)}><i className="down-arrow"/> Show me how</button>
                            </div>
                            <div className="serv-drop" style={timeActive === 0 ? {display:'none'} : {}} ref={timeRef}>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Start with a strong foundation</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Establish goals to be achieved</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Prioritize goals as per the timeline</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Adapt to new developments</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Continue on the roadmap</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-4">
                        <div className="column-wrap mid">
                            <motion.div  className="serv-head time" className="serv-head apps" onClick={appActive === 0 ? () => setAppActive(1) : () => setAppActive(0)} whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: .2
                                }
                                }}>
                                <h3>Building Apps <br/>From Scratch</h3>
                            </motion.div>
                            <div className="txt-wrap">
                                <p>Our apps are well built and designed to help with user accessibility. Creating and maintaining an app properly takes time. With our app creating expertise, supported by our digital marketing knowledge we will provide you with a first class solution.</p>
                                <button className="button" onClick={appActive === 0 ? () => setAppActive(1) : () => setAppActive(0)}><i className="down-arrow"/> Show me how</button>
                            </div>
                            <div className="serv-drop" style={appActive === 0 ? {display:'none'} : {}} ref={appRef}>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Start with a great idea</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get in touch with your team</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get updates on Development</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get digital marketing support</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Launch your amazing App!</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-4">
                        <div className="column-wrap far">
                        <motion.div  className="serv-head web" onClick={webActive === 0 ? () => setWebActive(1) : () => setWebActive(0)} whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: .2
                                }
                                }}>
                                <h3>Developing Web <br/>And Desktop Apps</h3>
                            </motion.div>
                            <div className="txt-wrap">
                                <p>Application development is an integral part of any business that aims at reaching its target audience directly. We provide custom application development services with responsive design that ensures your users get the best experience.</p>
                                <button className="button" onClick={webActive === 0 ? () => setWebActive(1) : () => setWebActive(0)}><i className="down-arrow"/> Show me how</button>
                            </div>
                            <div className="serv-drop" style={webActive === 0 ? {display:'none'} : {}} ref={webRef}>
                            <div className="drop-item">
                                    <div className="point"/>
                                    <p>Start with a great idea</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get in touch with your team</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get updates on Development</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Get first class SEO</p>
                                </div>
                                <div className="drop-item">
                                    <div className="point"/>
                                    <p>Monitor your new solution!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}