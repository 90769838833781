import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "../../assets/styles/hero2styles.scss"
import laptopDark from "../../assets/images/laptop-dark.jpg"
import downArrow from "../../assets/images/arrow-down.svg"

export default function Hero2() {
    return (
        <section className="section main-focus">
            <div className="container">
                <div className="content">
                    <h4>main focus when creating</h4>
                    <h3>We take pride in the solutions we create</h3>
                    <p>We focus on the right solution to drive our clients’ business forward and employ a technology-agnostic approach, allowing access to current, robust, and appropriate solutions. Paramount to this is ensuring the security and complaint use of all information.</p>
                    <AniLink to="/expertise" swipe direction="right">
                    <a><h5><i/>View our services</h5></a>
                    </AniLink>
                </div>
            </div>
        </section>
    )
}
