import React from 'react'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { normalizePath } from "../../utils/get-url-path"

import "../../assets/styles/blogshomestyles.scss"
import blogPlaceholder from "../../assets/images/blog-placeholder.jpg"

export default function Blogs(blogs) {
    const blogInfo = blogs.blogs;

    return ( 
        <>
                {blogInfo.length ? (
                    blogInfo.map((blog)  => 
                        <div className="column is-4" key={blog.id}>
                            <div className="wrapper">
                                <div className="product-image-wrap" >
                                    {blog.featuredImage ? 
                                        <Img fluid={blog.featuredImage.node.remoteFile.childImageSharp.fluid} />
                                    :
                                        <img src={blogPlaceholder} alt=""/>
                                    }
                                    
                                </div>
                                <h3>{blog.title}</h3>
                                <div className="author-date">
                                    <h5>By {blog.author.node.firstName}</h5>
                                    <h5>{blog.date.split("T").shift()}</h5>
                                </div>
                                <div className="blog-excerpt">
                                    <p>{blog.blogPostMeta.blogExcerpt.split(" ").splice(0,19).join(" ") + "..."}</p>
                                </div>
                                <div className="blog-link">
                                    <AniLink to={normalizePath(blog.uri)} swipe direction="right">
                                        <a>Read more</a>
                                    </AniLink>
                                </div>
                            </div>
                        </div>
                    
                    
                    )
                ) : ''}
    </>
    )
}
