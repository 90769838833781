import React from 'react'
import Img from "gatsby-image"

import "../../assets/styles/teamhomestyles.scss"
import teamPlaceholder from "../../assets/images/team-placeholder.jpg"

export default function Team(team) {
    const teamInfo = team.team;

    return (
        <div className="columns team-members">
            {teamInfo.length ? (
                    teamInfo.map((team)  => 

                    <div className="column is-3" key={team.id}>
                        {team.teamContentMeta.teamImage ? 
                        <Img fluid={team.teamContentMeta.teamImage.remoteFile.childImageSharp.fluid} />
                        :
                        <img src={teamPlaceholder} alt=""/>
                        }
                        <h5>{team.teamContentMeta.teamName}</h5>
                        <p>{team.teamContentMeta.teamPosition}</p>
                    </div>

            )) : ''}
        </div>
    )
}
