import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "../../assets/styles/showcasehomestyles.scss"

import DashboardImage from "../../assets/images/athlete-co-complete.png"
import watchImage from "../../assets/images/smart-watch.jpg"

export default function ShowcaseHome() {
    return (
        <section className="section showcase-home has-text-centered">
            <div className="container has-text-centered">
                <h3>Check out our portfolio, and learn more about our commitment to quality</h3>

                <AniLink to="/projects" swipe direction="right"><a><h5><i/>Explore</h5></a></AniLink>
            </div>
            <img src={DashboardImage} alt=""/>
        </section>
    )
}
