import React from 'react'
import { motion } from 'framer-motion';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "../../assets/styles/producthomestyles.scss"

import pieImage from "../../assets/images/pie.svg"
import shieldImage from "../../assets/images/shield.svg"
import cloudImage from "../../assets/images/cloud.svg"
import layersImage from "../../assets/images/layers.svg"
import downArrow from "../../assets/images/arrow-down.svg"

export default function ProductHome() {
    return (
        <section className="section home-products">
            <div className="container">
                <div className="columns">
                    <div className="column is-6 has-text-centered">
                        <div className="home-product">
                            <img src={pieImage} alt=""/>
                            <h3>Communication</h3>
                            <p>We work closely with our clients to evaluate their current position, as well as design the future strategy with end-to-end implementation and guaranteed outcomes.</p>
                        </div>
                        <AniLink to="/expertise" swipe direction="right"><a>
                        <motion.div className="home-product anim" whileHover={{
                            scale: 1.05,
                            transition: {
                                duration: .1
                            }
                        }}>
                            <img src={shieldImage} alt=""/>
                            <h4>Timely and effiecent</h4>
                            <h3>The right business intelligence empowers businesses to make smart and quick decisions.</h3>
                            <h5><i/>Explore</h5>
                        </motion.div>
                        </a></AniLink>
                    </div>
                    <div className="column is-6 has-text-centered">
                    <AniLink to="/expertise" swipe direction="right"><a>
                        <motion.div className="home-product anim"whileHover={{
                            scale: 1.05,
                            transition: {
                                duration: .1
                            }
                        }}>
                            <img src={cloudImage} alt=""/>
                            <h4>Focus and Quality</h4>
                            <h3>We believe technology is a new inclusive economic context, give your company the advantage today.</h3>
                            <h5><i/>Explore</h5>
                        </motion.div>
                        </a></AniLink>
                        <div className="home-product alt">
                            <div className="layer">
                                <img src={layersImage} alt=""/>
                                <img src={layersImage} alt="" className="layer-img"/>
                            </div>
                            <h3>Results</h3>
                            <p>Precisely matching technology to the needs of your business is our core expertise. We make the impossible, possible through technology making your digital transformation valuable.</p>
                        </div>
                        
                    </div>
                </div>
             </div>
        </section>
    )
}
