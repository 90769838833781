import React, {useState} from "react"
import { motion } from 'framer-motion';

import "../../assets/styles/testimonialshomestyles.scss"

import quoteIcon from "../../assets/images/quote.svg"
import navIcon from "../../assets/images/next.svg"

import reactIcon from "../../assets/images/React-icon.svg"
import nodeIcon from "../../assets/images/Node_logo.svg"
import phpIcon from "../../assets/images/php-logo.svg"
import angularIcon from "../../assets/images/Angular.svg"

export default function TestimonialsHome() {


    const testOneName = "GREENFIELDS"
    const testOneContent = "Right from day one the quality of service, communication, timekeeping and quality of the staff has been absolutely marvelous. It has been a great experience working with them. They are professional and communicative. We are elated with the results."

    const testTwoName = "SHLOOP"
    const testTwoContent = "Their team has always managed to complete my projects within the deadlines. I genuinely want to thank the team for transforming our raw ideas and designs into reality. I would also like to commend them for their knowledgeable employees and excellent service. I look forward to working with them on future projects."

    const testThreeName = "REDTEX"
    const testThreeContent = "I am glad to write a review for your capable team, you have onboard and I would like to mention another thing that your technology team is gold for your company. Thanks for everything. Soon we’ll be outsourcing more projects to you."

    const [testActive, setTestActive] = useState(1);

    function paginationTestNext() {
        if(testActive === 1){
            setTestActive(2)
        } else if(testActive === 2){
            setTestActive(3)
        }
    }
    function paginationTestPrev() {
        if(testActive === 3){
            setTestActive(2)
        } else if(testActive === 2){
            setTestActive(1)
        }
    }

    function currentTestName() {
        if(testActive === 1){
            return testOneName
        } else if(testActive === 2){
            return testTwoName
        } else if(testActive === 3){
            return testThreeName
        }
    }
    function currentTestContent() {
        if(testActive === 1){
            return (
                <motion.p classname="2" initial="hidden" animate="visible" variants={{
                    hidden: {
                      scale: .8,
                      opacity: 0
                    },
                    visible: {
                      scale: 1,
                      opacity: 1,
                      transition: {
                        delay: .4
                      }
                    },
                  }}>{testOneContent} </motion.p>
            )
        } else if(testActive === 2){
            return (
                <motion.div classname="1" initial="hidden" animate="visible" variants={{
                    hidden: {
                      scale: .8,
                      opacity: 0
                    },
                    visible: {
                      scale: 1,
                      opacity: 1,
                      transition: {
                        delay: .3
                      }
                    },
                  }}><p>{testTwoContent}</p> </motion.div>
            )
        } else if(testActive === 3){
            return (
                <motion.p classname="2" initial="hidden" animate="visible" variants={{
                    hidden: {
                      scale: .8,
                      opacity: 0
                    },
                    visible: {
                      scale: 1,
                      opacity: 1,
                      transition: {
                        delay: .4
                      }
                    },
                  }}>{testThreeContent} </motion.p>
            )
        }
    }


    return (
        <section className="section testimonials-home">
            <div className="container">
                <h3>We provide our clients with a great solution, that gives them peace of mind</h3>
                <div className="testimonials">
                    <div className="controls">
                        <h4>{"Client testimonials/ " + currentTestName() }</h4>
                        <div className="test-nav">
                            <div className={testActive === 1 ? "prev max" : "prev"} onClick={() => paginationTestPrev()}/>
                            <div className={testActive === 3 ? "next max" : "next"} onClick={() => paginationTestNext()}/>
                        </div>
                    </div>
                    <div className="quotes">
                        <img src={quoteIcon} alt=""/>
                        <div className="quote">
                            {currentTestContent()}
                        </div>
                    </div>
                </div>
                <div className="tech">
                    <img src={reactIcon} alt=""/>
                    <img src={nodeIcon} alt=""/>
                    <img src={phpIcon} alt=""/>
                    <img src={angularIcon} alt=""/>
                </div>
            </div>
        </section>
    )
}
