import React from 'react'
import { motion } from 'framer-motion';

import "../../assets/styles/herostyles.scss"

import devHero from "../../assets/images/develoscope-hero.png"
import heroImage from "../../assets/images/develoscope-laptop.jpg"

export default function HeroHome() {
    return (
        <section className="hero-home">
            <div className="container">
            <motion.div initial="hidden" animate="visible" variants={{
                hidden: {
                    scale: .8,
                    opacity: 0
                },
                visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                    delay: .4
                    }
                },
            }}>
                <div className="hero-intro has-text-centered">
                    <img src={devHero} alt="Develoscope-Icon" />
                    <h1>We provide software solutions for your burning ideas</h1>
                </div>
            </motion.div>

                <div className="intro-desc">
                    <div className="columns">
                        <motion.div initial="hidden" animate="visible" className="column is-4" variants={{
                            hidden: {
                                scale: .8,
                                opacity: 0
                            },
                            visible: {
                                scale: 1,
                                opacity: 1,
                                transition: {
                                delay: .5
                                }
                            },
                        }}>
                            <h3>Connect</h3>
                            <p>Feel free to contact us about your ideas or business proposals. Our experts will evaluate and give you honest feedback, every idea can be transformed into something amazing. Do not be shy and contact us.</p>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" className="column is-4" variants={{
                            hidden: {
                                scale: .8,
                                opacity: 0
                            },
                            visible: {
                                scale: 1,
                                opacity: 1,
                                transition: {
                                delay: .6
                                }
                            },
                        }}>
                            <h3>Collaborate</h3>
                            <p>We consist of experts in various fields of IT be it development, management, sales, consulting, all tailored for your needs and requirements. Ours is a group of exceptional talents guided and motivated by remarkable leaders.</p>
                        </motion.div>
                        <motion.div initial="hidden" animate="visible" className="column is-4" variants={{
                            hidden: {
                                scale: .8,
                                opacity: 0
                            },
                            visible: {
                                scale: 1,
                                opacity: 1,
                                transition: {
                                delay: .7
                                }
                            },
                        }}>
                            <h3>Develop</h3>
                            <p>Precisely matching technology to the needs of your business is our core expertise. We crafted our suite of services to be flexible and responsive. Our entire focus is delivering fast, impactful results in your digital initiatives.</p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    )
}
