import React from "React";
import Layout from "../components/Global/Layout";
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";


import HeroHome from "../components/Home/HeroHome"
import ServiceHome from "../components/Home/ServiceHome"
import Hero2 from "../components/Home/Hero2Home";
import ChoiceHome from "../components/Home/ChoiceHome";
import ProductHome from "../components/Home/ProductHome";
import ShowcaseHome from "../components/Home/ShowcaseHome";
import TestimonialsHome from "../components/Home/TestimonialsHome";
import Team from "../components/Global/Team";
import Blogs from "../components/Global/Blog";


export const query = graphql`
  query Index {
    allWpPost(limit: 3) {
        nodes {
          author {
            node {
              id
              firstName
              lastName
            }
          }
          featuredImage {
            node {
              id
              remoteFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 300) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
          id
          title
          uri
          date
          blogPostMeta {
            blogExcerpt
          }
        }
      }

      allWpTeam(limit: 4) {
        nodes {
          id
          teamContentMeta {
            teamName
            teamPosition
            teamImage {
              id
              remoteFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 206, maxHeight: 206) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
        }
      }
  }
`

export default (data) => {

    const current = "home"

    console.log("HOME QUERY " + data.data)
    return(
        <>
        
        <Layout current={current}>
            <HeroHome/>
            <ServiceHome/>
            <Hero2/>
            <ChoiceHome/>
            <ProductHome/>
            <section className="section home-quote">
                <div className="container">
                    <div className="quote">
                        <h4>“Let’s go invent tomorrow instead of worrying about what happened yesterday.”</h4>
                    </div>
                </div>
            </section>
            <ShowcaseHome/>
            <TestimonialsHome/>
            <section className="section home-blogs">
              <div className="container">
                <div className="columns">
                  <Blogs blogs={data.data.allWpPost.nodes}/>
                </div>
              </div>
            </section>
            <section className="section home-team">
              <div className="container">
                <h3>A Company is only as good as its Team</h3>
                <p>We actively encourage an efficient, healthy and friendly work enviroment at Develoscope, to increase workplace productivity, and reaffirm our commitment to providing our clients with the highest quality solutions.</p>
                <Team team={data.data.allWpTeam.nodes}/>
                <div className="has-text-centered">
                  {/* <AniLink to="/team" swipe direction="right">
                  <button className="button home">View our Team</button>
                  </AniLink> */}
                  <h4>+30 more professionals eager to make your next project amazing!</h4>
                </div>
                
              </div>
            </section>
        </Layout>
        </>
    )
}