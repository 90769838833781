import React from 'react'

import "../../assets/styles/choicehomestyles.scss"

import BookIcon from "../../assets/images/book.svg"
import ChartIcon from "../../assets/images/chart.svg"
import GalIcon from "../../assets/images/gallery.svg"
import TagIcon from "../../assets/images/tag.svg"
import WindowIcon from "../../assets/images/window.svg"
import ClockIcon from "../../assets/images/clock.svg"

export default function ChoiceHome() {
    return (
        <section className="section choice-home">
            <div className="container">

                <div className="columns">
                        <div className="column is-4 flex-col">
                            <div className="book-icon"/>
                            <img src={BookIcon} alt=""/>
                            <div className="content">
                                <h3>Software development</h3>
                                <p>We try our best to simplify the software development process by embracing the joy of creating something unique that is easy for end users.</p>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <img src={ChartIcon} alt=""/>
                            <div className="content">
                                <h3>Web development</h3>
                                <p>Web solutions we develop are future-rich, highly functional, robust, secure and scalable with engaging UI/UX design that enhances the brand.</p>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <div className="content highlight">
                                <h3>“What separates design from art is that design is meant to be functional”</h3>
                            </div>
                        </div>
                </div>
                <div className="columns">
                        <div className="column is-4 flex-col">
                            <img src={GalIcon} alt=""/>
                            <div className="content">
                                <h3>Mobile App</h3>
                                <p>We ship rich experiences to the small screens, which translates into the success of your business, ease of operations and greater customer engagement.</p>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <div className="content highlight">
                                <h3>“A user interface is like a joke. If you have to explain it, it’s not that good”</h3>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <img src={TagIcon} alt=""/>
                            <div className="content">
                                <h3>UI/UX design</h3>
                                <p>Crafting a visually appealing and functional interface, by carefully orchestrating interface, information and interaction.</p>
                            </div>
                        </div>
                </div>

                <div className="columns">
                        <div className="column is-4 flex-col">
                            <div className="content highlight">
                                <h3>“Website without visitors is like a ship lost in the horizon”</h3>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <img src={WindowIcon} alt=""/>
                            <div className="content">
                                <h3>SEO optimization</h3>
                                <p>We use state-of-the-art methods and techniques we help you acquire a better position for your website on Google's search engine.</p>
                            </div>
                        </div>
                        <div className="column is-4 flex-col">
                            <img src={ClockIcon} alt=""/>
                            <div className="content">
                                <h3>QA testing</h3>
                                <p>Our QA experts will perform comprehensive analysis of the requirements to come up with a QA testing strategy and execution planning.</p>
                            </div>
                        </div>
                </div>


            </div>
        </section>
    )
}
